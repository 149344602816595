import { createSlice } from "@reduxjs/toolkit";
import { Project } from "./projectSlice";
import { GetDocumentType } from "../../Components/Documents/Documents";

export interface DocumentType{
    get_id: string;
    isActive: boolean;
    name: string;
    description: string;
    url: string;
    projectId: string;
    folderId:string;
}
export interface FolderType{
    get_id: string;
    isActive: boolean;
    name: string;
    description: string;
    projectId: string;
}


export interface DocumentsState{
    projectsForDocuments:Array<Project|null>
    folderData:[];
    loading : boolean;
    selectedProject:string;
    documentLoding:boolean;
    documentsOfAProject:Array<GetDocumentType | null>;
    dialogOpenStatus:boolean;
    folderDialogOpenStatus:boolean;
    document:DocumentType;
    folder: FolderType;
    folderDataShow:boolean;
    selectedFolder:string;
    searchFolder:string;
}


export const intialDocument : DocumentType = {
    get_id: "",
    isActive: true,
    name: "",
    description: "",
    url: "",
    projectId: "",
    folderId:"",
  }
  export const intialFolder : FolderType = {
    get_id: "",
    isActive: true,
    name: "",
    description: "",
    projectId: ""
  }

const initialState : DocumentsState = {
    projectsForDocuments : [],
    loading : false,
    selectedProject:'',
    documentLoding:false,
    documentsOfAProject:[],
    dialogOpenStatus:false,
    document:intialDocument,
    folderDialogOpenStatus:false,
    folder:intialFolder,
    folderDataShow:false,
    folderData:[],
    selectedFolder:"",
    searchFolder:"",
}

export const documentSlice = createSlice({
    name: 'documentSlice',
    initialState,
    reducers:{
        setProjectsForDocuments:(state,action) => {
            state.projectsForDocuments = action.payload;
        },
        setLoadingForProjects:(state,action) => {
            state.loading = action.payload;
        },
        setSelectedProject:(state,action) => {
            state.selectedProject = action.payload;
        },
        setLoadingForDocuments:(state,action) => {
            state.documentLoding = action.payload;
        },
        setDocumentsForAProject:(state,action) => {
            state.documentsOfAProject = action.payload;
        },
        setDialogStatus:(state,action) => {
            state.dialogOpenStatus = action.payload;
        },
        setFolderDialogStatus:(state,action) => {
            state.folderDialogOpenStatus = action.payload;
        },
        setDocument:(state,action) => {
            state.document = action.payload;
        },
        setFolder:(state,action) => {
            state.folder = action.payload;
        },
        setFolderDataShow:(state,action) => {
            state.folderDataShow = action.payload;
        },
        setFolderData:(state,action) => {
            state.folderData = action.payload;
        },
        setSelectedFolder:(state,action)=>{
            state.selectedFolder = action.payload
        },
        setSearchFolder:(state,action)=>{
            state.searchFolder = action.payload
        },
        resetDocumentState: () => initialState,
    }
})

export const {setProjectsForDocuments,setLoadingForProjects,setSelectedProject,setLoadingForDocuments,setDocumentsForAProject,setDialogStatus,setDocument,setFolderDialogStatus,setFolder,setFolderDataShow,setFolderData,setSelectedFolder,setSearchFolder,resetDocumentState} = documentSlice.actions;

export default documentSlice.reducer;