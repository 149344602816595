import S3 from "react-aws-s3-typescript";
import AWSconfig from "../config/config.json";
import { DecodedToken } from "../Components/Auth/OTP";
import { jwtDecode } from "jwt-decode";

export const upload_file_to_s3 = (
  path: string,
  file: File,
  filename: string,
  bucket = "cambri"
) => {
  const s3config = {
    bucketName: bucket,
    dirName: path,
    region: "ap-south-1",
    accessKeyId: AWSconfig.accessKeyId,
    secretAccessKey: AWSconfig.secretAccessKey,
  };

  const ReactS3Client = new S3(s3config);
  const newFileName = filename;
  return ReactS3Client.uploadFile(file, newFileName);
};

export const handleDownload = async (url : string) => {
  const s3Url = url;
  try {
    const response = await fetch(s3Url);
    const blob = await response.blob();

    const downloadLink = document.createElement("a");
    downloadLink.href = window.URL.createObjectURL(blob);

    const urlParts = s3Url.split("/");
    const filename = urlParts[urlParts.length - 1]; //need to change as per filename

    downloadLink.setAttribute("download", filename);
    document.body.appendChild(downloadLink);
    downloadLink.click();

    document.body.removeChild(downloadLink);
  } catch (error) {
    console.error("Error downloading file:", error);
  }
};

export const setItemForLocal = (key : string, value : any) => {
  try {
    localStorage.setItem(key, value);
  } catch (error) {
    console.error('Error setting item in localStorage', error);
  }
};

export const getItemFromLocal = (key:string) => {
  try {
    const item = localStorage.getItem(key);
    return item;
  } catch (error) {
    console.error('Error getting item from localStorage', error);
    return null;
  }
};

export const removeItemFromLocal = (key:string) => {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    console.error('Error removing item from localStorage', error);
  }
};

export const clearLocalStorage = () => {
  try {
    localStorage.clear();
  } catch (error) {
    console.error('Error clearing localStorage', error);
  }
};

export const getRoles = () => {
  let token = getItemFromLocal('authToken');
  if(token){
    let decoded : DecodedToken = jwtDecode(token);
    return decoded.roles;
  }else{
    window.location.href = '/login';
    return [];
  } 
}

export const formatDate = (newdate : string) => {
  const date = new Date(newdate);
  const day = date.getDate();
  const month = date.toLocaleString('default', { month: 'short' });
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${day} ${month} ${year} ${hours}:${minutes}`;
}